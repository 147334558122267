import { useEffect } from 'react';

const LoginContinue = ({setLoggedIn}) => {

	useEffect(() => {
		const authenticateUser = async () => {
			try {
				fetch(`/api/verify/tm/token`, {
					method: 'GET',
					credentials: 'include'
				})
				.then(response => {
					if(response.ok){
						setLoggedIn(true);
					} else{
						setLoggedIn(false);
						throw new Error('Authentication failed');
					}
				})
			} catch (error) {
				console.error('Network error:', error);
				setLoggedIn(false);
			}
		};
	
		authenticateUser();
	}, []);
};

export default LoginContinue;