import { useEffect } from "react";


const LoginError = ({setLoggedIn, setLoginError}) => {
    useEffect(()=>{
        setLoggedIn(false);
        setLoginError(true);
    }, [])
};

export default LoginError;