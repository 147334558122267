import {
  Flex,
  Text,
  Image,
  Divider,
  Icon,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from '@chakra-ui/react';
import { BiPowerOff } from "react-icons/bi";
import { useEffect, useState } from 'react';
import { AiOutlineUser } from "react-icons/ai";
import '../../styles/navbar.css'
import { jwtDecode } from 'jwt-decode';



export default function Navbar({ setLoggedIn }) {
  return (
      <Flex 
      className='dashboard-navbar'
      gap={4}
      direction={'row'}
      justify={'space-between'}>
          <Image
          height={'24px'}
          width={'150px'}
          objectFit='contain'
          src={process.env.PUBLIC_URL + '/cisco_spaces_logo_inline.svg'}
          alt='Cisco DNASpaces Logo'/>
          <AccountDropDown setLoggedIn={ setLoggedIn }/>
      </Flex>
  );
}

function AccountDropDown({ setLoggedIn}){
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = async () => {
    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.token) {
      fetch('/api/user/logout', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        if(response.ok){
          console.log("Response OK");
        }else{
          throw new Error('Network response was not ok');
        }
      })
      .catch(error => {
        console.error('Error during logout:', error);
      })
      .finally(() =>{
        setIsLoading(false);
        setLoggedIn(false);
        localStorage.removeItem("user");
      })
    }
  }

  useEffect(()=>{
    const user = JSON.parse(localStorage.getItem("user"));
    if(user && user.token){
      // user.token is a signed jwt, so decode it to get the key username
      const decoded = jwtDecode(user.token);
      setUsername(decoded.username);
    }
  }, []);

  return (
    <>
      <Flex 
        gap={4} 
        direction={'row'} 
        align={'center'}>
        <Icon>
          <AiOutlineUser color='white' size={'30px'}/>
        </Icon>
        <Text color={'white'}>{username}</Text>
        <Divider orientation="vertical" borderColor="white" height="30px" />
        <IconButton 
          size={"md"}
          variant='solid'
          fontSize="20px"
          colorScheme='white'
          aria-label='Logout'
          icon={<BiPowerOff />}
          onClick={onOpen}
        />
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Logout</ModalHeader>
          <ModalBody>
            Are you sure you want to log out?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleLogout} isLoading={isLoading}>
              Log Out
            </Button>
            <Button variant="ghost" onClick={onClose} ml={3}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}