import { useState, useEffect } from 'react';
import { Flex, 
    VStack, 
    FormControl, 
    InputGroup, 
    InputLeftElement, 
    Input, 
    Button, 
    FormErrorMessage, 
    Text,
    Image} from '@chakra-ui/react';
import { FaRegEnvelope } from 'react-icons/fa';
import '../../styles/login.css'; 
import { validateEmail } from '../../utils/Utility';
import '../../styles/background.css'
import LoadingPage from '../Loader';
import useImagePreloader from '../../utils/ImagePreloader';


const loginClick = (email) => {
    let loginUri = `/api/tm/v1/account/login/${email}?app=dnaspaces&supportlogin=true`;
    window.location.href = loginUri;
};

const MainLogin =({loginError}) => {
    const [email, setEmail] = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [showLoginError, setShowLoginError] = useState(false);

    const imageSrc = process.env.PUBLIC_URL + '/cisco_spaces_logo.svg';
    const loaded = useImagePreloader(imageSrc);

    const handleLogin = (e) => {
        e.preventDefault();
        if (!email) {
            setEmailErrorMessage("Email is required.");
            setEmailError(true);
        } else if (!validateEmail(email)) {
            setEmailErrorMessage("Incorrect email.");
            setEmailError(true);
        } else {
            setEmailError(false);
            loginClick(email, false, false);
        }
    };

    useEffect(()=>{
        if(loginError){
            setShowLoginError(true);
            const timer = setTimeout(()=>{
                setShowLoginError(false);
            }, 60000);
            return () => clearTimeout(timer);
        }
    }, [loginError]);

    if(!loaded){
        return (
            <LoadingPage/>
        )
    }
    return (
        <div className="background-svg">
            <Flex className="login-container" alignItems={"center"} justifyContent={"center"}>
                <Flex
                    className="login-box"
                    alignItems={"center"}
                    justifyContent={"center"}
                    direction={"column"}
                    gap={"100px"}
                >
                    <Flex gap={"20px"} direction={"column"} align={"center"} justify={"center"}>
                        <Image
                            width={"250px"}
                            objectFit="contain"
                            src={process.env.PUBLIC_URL + "/cisco_spaces_logo.svg"}
                            alt="Cisco DNASpaces Logo"
                        />
                        <Text className="title-brand-login">Intelligent Monitoring Dashboard</Text>
                    </Flex>

                    <Flex direction="column" align="center" width="100%" gap={"8px"}>
                        <VStack as="form" spacing={4} width="80%" alignItems="center" onSubmit={handleLogin}>
                            <Flex direction={"column"} gap={"8px"} width={"100%"}>
                                {showLoginError && <Text className='login-error-text'>Error occurred while trying to login</Text>}
                                <FormControl isInvalid={emailError}>
                                    <InputGroup style={{ borderColor: showLoginError ? "rgb(212, 12, 12,1)" : "rgba(200,200,200,1)" }}>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<FaRegEnvelope color="rgba(200,200,200,1)" />}
                                        />
                                        <Input
                                            type="email"
                                            placeholder="Email ID"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                        />
                                    </InputGroup>
                                    {emailError && <FormErrorMessage>{emailErrorMessage}</FormErrorMessage>}
                                </FormControl>
                            </Flex>
                            <Button
                                borderRadius={5}
                                type="submit"
                                variant="solid"
                                colorScheme="blue"
                                width="full"
                                height={"50px"}
                            >
                                <Text className="login-modal-continue">Login</Text>
                            </Button>
                        </VStack>
                    </Flex>
                </Flex>
            </Flex>
        </div>
    );
}

export default MainLogin;