import Dashboard from './component/dashboard/Dashboard.js';
import MainLogin from './component/login/MainLogin.js';
import LoginContinue from './component/login/LoginContinue.js';
import LoginError from './component/login/LoginError.js';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Navigation() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [loginError, setLoginError] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        fetch('/api/verify/token', {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            if(response.ok){
                setLoggedIn(true);
                navigate('/dashboard');
            } else{
                setLoggedIn(false);
            }
        })
    }, [navigate]);

    useEffect(() => {
        if(loggedIn) {
            navigate('/dashboard');
        } else {
            navigate('/login');
        }
    }, [loggedIn, navigate]);

    return(
        <Routes>
            <Route path="/login" element={<MainLogin loginError={loginError}/>}/>
            <Route path="/login/supportcontinue" element={<LoginContinue setLoggedIn={setLoggedIn}/>}/>
            <Route path="/login/error" element={<LoginError setLoggedIn={setLoggedIn} setLoginError={setLoginError}/>}/>
            <Route path="/dashboard" element={<Dashboard loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>}/>
            <Route path="/healthcheck" element={<HealthCheck />} />
        </Routes>
    )
}

const HealthCheck = () => (
    <h4>I am Healthy: backend server endpoint {window._env_.REACT_APP_API_URL}</h4>
);

function App() {
    return(
        <BrowserRouter>
            <Navigation />
        </BrowserRouter>
    )
}
export default App;
