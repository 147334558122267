import React from 'react';
import {Divider, Grid, GridItem, HStack, Box, Button, Flex, Heading, Spacer, useDisclosure } from '@chakra-ui/react';
import Filters from './Filters';
import { useState, useEffect } from 'react'
import Navbar from './Navbar';
import Data from './Data';
import { fetchData, useInterval } from '../../utils/Utility';
import { useNavigate } from 'react-router-dom';
import AlertBox from './AlertBox';

function Dashboard({loggedIn, setLoggedIn}) {

	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [query, setQuery] = useState("");
	const [timeRange, setTimeRange] = useState("min30");
	const [data, setData] = useState([]);
	const [lastUpdated, setLastUpdated] = useState("");
	const { isOpen, onOpen, onClose } = useDisclosure(); //For The Alert Dialog box
	const [alertLoading, setAlertLoading] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]); // state to store selected rows

    const [selectedFilters, setSelectedFilters] = useState(["App", "Library"]);

	const [liveMode, setLiveMode] = useState(false);
    const pollingInterval = 10000;

	
    useInterval(()=> {
		const timestamp = new Date().getTime();
        fetchData(query, timeRange, selectedFilters, setData, setIsLoading, false);
		setLastUpdated(timestamp);
    }, liveMode? pollingInterval:null);

	// Default when the page reloads or loads for the first time
	useEffect(()=>{
		if(loggedIn){
			const timestamp = new Date().getTime();
			fetchData(query, timeRange, selectedFilters, setData, setIsLoading);
			setLastUpdated(timestamp);
		}else{
			const user = JSON.parse(localStorage.getItem("user"));
			if(!user || !user.token){
				setLoggedIn(false);
				navigate('/login');
				return;
			}

			fetch('/api/verify/token', {
				method: "POST",
                credentials: 'include',
				headers: {
					'jwt-token': user.token,
				}
			})
			.then(r => r.json())
			.then(r => {
				if('success' === r.message){
					setLoggedIn(true);
					const timestamp = new Date().getTime();
					fetchData(query, timeRange, selectedFilters, setData, setIsLoading);
					setLastUpdated(timestamp);
				} else {
					setLoggedIn(false);
					navigate('/login');
					return;
				}
			});
		}
	}, []);

	const resolveSelected = async ()=>{
		setAlertLoading(true)
        let myHeaders = {
            'Content-type': 'application/json; charset=UTF-8',
        };
        let raw = JSON.stringify({
            "ids": selectedRows
        });
            
        let requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            credentials: "include",
            body: raw,
            redirect: 'follow'
        };
        fetch('/api/update', requestOptions)
        .then(response => response.json()) // Parse the response as JSON
        .then(d => {
            if(d.success){
                let updatedData = data.map(item => {
                    if (selectedRows.includes(item.id)) {
                        return { ...item, record: {...item.record, resolved: true} }
                    }
                    return item;
                });
                setData(updatedData); 
                setSelectedRows([]);
            } else{
                console.error('An error occured while updating the rows on server side');
                alert(`Couldn't update the rows`);
            }
        })
        .catch(error => alert(`Error ${error}`))
		.finally(()=>{
			setAlertLoading(false)
			onClose();
		});
    }

	const clearResolveSelected = ()=>{
		setSelectedRows([]);
	}
	//----------------------------------------------Components------------------------------------------------------------------------------------
	return (
        <div>
            <Navbar setLoggedIn={ setLoggedIn }/>
            <Flex
                direction={"column"}
                h="100%"
                marginTop={"0px"}
                paddingTop={"20px"}
                gap="20px"
                color="blackAlpha.700"
                backgroundColor={"rgba(250, 255, 254, 1)"}
                fontWeight={"bold"}
            >
                <Filters
                    setData={setData}
                    setLastUpdated={setLastUpdated}
                    query={query}
                    setQuery={setQuery}
                    timeRange={timeRange}
                    setTimeRange={setTimeRange}
                    liveMode={liveMode}
                    setLiveMode={setLiveMode}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setSelectedRows={setSelectedRows}
                    selectedFilters={selectedFilters}
                />
                <Data
                    data={data}
                    setData={setData}
                    lastUpdated={lastUpdated}
                    setLastUpdated={setLastUpdated}
                    query={query}
                    timeRange={timeRange}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    liveMode={liveMode}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                />
            </Flex>
            {selectedRows.length !== 0 && (
                <Box
                    position="fixed"
                    bottom={0}
                    width="100%"
                    zIndex="1"
                    bg="rgba(224, 255, 252, 1)"
                    pl={"5vw"}
                    pt={"2vh"}
                    pb={"2vh"}
                    pr={"5vw"}
                    borderRadius={"md"}
                    boxShadow={""}
                >
                    <Flex alignItems={"center"}>
                        <Heading fontSize="2vh">
                            {selectedRows.length} {selectedRows.length === 1 ? "Alert" : "Alerts"} Selected
                        </Heading>
                        <Spacer />
                        <HStack>
                            <Button onClick={clearResolveSelected} colorScheme="red">
                                Clear All
                            </Button>
                            <Button onClick={onOpen} colorScheme="blue">
                                Mark as Resolved
                            </Button>
                        </HStack>
                    </Flex>
                </Box>
            )}
            <AlertBox
                alertMessage={"Are you sure you want to mark " + selectedRows.length + " alerts as resolved?"}
                alertTopic={"Resolve"}
                acceptAlertOption={"Resolve"}
                declineAlertOption={"Cancel"}
                isOpen={isOpen}
                onClose={onClose}
                onAccept={resolveSelected}
                alertLoading={alertLoading}
            />
        </div>
    );
}
export default Dashboard;
